import {
  TopRated,
  BaccaratIcon,
  RouletteIcon,
  BlackjackIcon,
  DragonTigerIcon,
  PokerIcon,
  GameShowIcon,
  FirstPersonGameIcon,
  AllCasinoIcon,
  FireSolid,
} from "screens/Home/common/icons"

import GameType from "screens/Casino/types/GameType"
import GameFilter from "screens/Casino/types/GameFilter"

export const liveCasinoItems: GameFilter[] = [
  {
    value: GameType.All,
    name: "모든",
    Icon: AllCasinoIcon,
  },
  {
    value: GameType.TopRated,
    name: "탑게임",
    Icon: TopRated,
  },
  {
    value: GameType.Baccarat,
    name: "바카라",
    Icon: BaccaratIcon,
  },
  {
    value: GameType.Roulette,
    name: "룰렛",
    Icon: RouletteIcon,
  },
  {
    value: GameType.Blackjack,
    name: "블랙잭",
    Icon: BlackjackIcon,
  },
  {
    value: GameType.DragonTiger,
    name: "식보 & 드래곤타이거",
    Icon: DragonTigerIcon,
  },
  {
    value: GameType.Poker,
    name: "포커",
    Icon: PokerIcon,
  },
  {
    value: GameType.GameShows,
    name: "게임쇼",
    customWidth: 64,
    Icon: GameShowIcon,
  },
  {
    value: GameType.FirstPerson,
    name: "일인칭 게임",
    customWidth: 86,
    Icon: FirstPersonGameIcon,
  },
  {
    value: GameType.Recommended,
    name: "당신에게 추천된",
    Icon: FireSolid,
  },
]

export const PLACEHOLDER_DESCRIPTION = `온라인 원탑..! 에볼루션, 그 중에서도 높은 인기와 관심을 받는 스피드 바카라 를 소개 해드립니다. 바카라 게임의 목적은 어느 핸드가 9에 가장 가까운 수를 얻어 이기게 될지를 예측 하는 게임 입니다. 총 52 장의 카드로 된 8개의 덱을 사용하여 진행되며 오리지널 바카라에 비해 게임당 평균 30초 를 넘지 않아 더욱 빠르게 뱅커와 플레이어를 예측 및 결과를 즐길수 있습니다.`

export const GameWithAnimation = [
  "PowerofThorMegaways",
  "SweetBonanza",
  "CaishensGold",
  "CurseoftheWerewolfMegaways",
  "DragonKingdom-EyesofFire",
  "EyeoftheStorm",
]

export const MOBILE_PAGE_SIZE = 8
export const PC_PAGE_SIZE = 32
export const MOBILE_LOAD_SIZE = 8
export const PC_LOAD_SIZE = 24
export const STALE_TIME = 6000000
export const ANIM_INTERVAL = 25
export const FADE_DELAY = 300
export const PC_SEARCH_BREAKPOINT = 1080

export const CASINO_QUERY_NAME = "CategorizedCasinoGames"
export const POPULAR_QUERY_NAME = "TopRatedCasinoGames"
export const RECENTLY_PLAYED_QUERY_NAME = "RecentlyPlayedCasinoGames"
export const BACCARAT_QUERY_NAME = "BaccaratCasinoGames"
export const ROULETTE_QUERY_NAME = "RouletteCasinoGames"
export const BLACKJACK_QUERY_NAME = "BlackjackCasinoGames"
export const DRAGON_TIGER_QUERY_NAME = "DragonTigerCasinoGames"
export const POKER_QUERY_NAME = "PokerCasinoGames"
export const GAME_SHOWS_QUERY_NAME = "GameShowsCasinoGames"
export const FIRST_PERSON_QUERY_NAME = "FirstPersonCasinoGames"
export const SEARCH_QUERY_NAME = "SearchCasinoGames"

export const SEARCH_TAG_FILTERS = [
  "전체게임",
  "바카라",
  "룰렛",
  "블랙잭",
  "식보 & 드래곤타이거",
  "포커",
  "게임쇼",
  "일인칭 게임",
]

// export const SPECIAL_TABLES = [
//   BACCARAT_CODE, // Baccarat Squeeze
//   KOREAN_SPEED_CODE, // Korean Speed Baccarat
// ]

export const KOREAN_SPEED_CODE = "onokyd4wn7uekbjx"
export const BACCARAT_SQUEEZE_CODE = "zixzea8nrf1675oh"
export const KOREAN_SPEAKING_SPEED_BACCARAT_1 = "npn3y3hkld2mcdjt"
export const KOREAN_SPEAKING_SPEED_BACCARAT_2 = "oga6ftnw3fltvggm"
export const KOREAN_SPEED_BACCARAT_A = "onokyd4wn7uekbjx"
export const BACCARAT_SQUEEZE = "zixzea8nrf1675oh"
export const BACCARAT_CONTROL_SQUEEZE = "k2oswnib7jjaaznw"

export const KOREAN_SPEAKING_SPEED_BACCARAT_1_LABEL = "KR 속도 바카라 1"
export const KOREAN_SPEAKING_SPEED_BACCARAT_2_LABEL = "KR 속도 바카라 2"
export const KOREAN_SPEED_BACCARAT_A_LABEL = "KR 스피드 바카라 A"
export const BACCARAT_SQUEEZE_LABEL = "스퀴즈"
export const BACCARAT_CONTROL_SQUEEZE_LABEL = "컨트롤 스퀴즈"

export const CUSTOM_TABLES = [KOREAN_SPEED_CODE, BACCARAT_SQUEEZE_CODE]
export const KOREAN_BACCARAT_TABLES = [
  KOREAN_SPEAKING_SPEED_BACCARAT_1,
  KOREAN_SPEAKING_SPEED_BACCARAT_2,
  KOREAN_SPEED_BACCARAT_A,
]
