/* tslint:disable */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useEffect, memo, useRef, useState, useContext} from "react"
import ReactGA from "react-ga4"
import {useQueryClient} from "react-query"
import smoothscroll from "smoothscroll-polyfill"
import includes from "lodash/includes"
import shallow from "zustand/shallow"
import {isAfter, subMinutes} from "date-fns"
import {isWindows} from "react-device-detect"
import servicePlayer from "services/Player"
import {refreshToken} from "config/init"
import {useUserStore} from "stores/userStore"
import usePageTransition from "utils/hooks/usePageTransition"
import themeService from "services/Theme"
import {
  BET_HISTORY_QUERY,
  BET_HISTORY_TOTALS_QUERY,
} from "screens/Home/MyPage/Screens/BetHistory/config"
import {
  AFFILIATE,
  AFFILIATE_REGISTER,
  CHARGE_EXCHANGE,
} from "screens/MyProfile/constants"
import {NAV_HOME_SELECTED_PAGE} from "screens/NavHome/utils/config"
import {AFFILIATE_DETAILS_QUERY} from "screens/MyProfile/screens/Affiliate/config"
import useInboxStore from "screens/MyProfile/store/useInboxStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import useMyProfileStore from "screens/MyProfile/store/useMyProfileStore"
import useAffiliateStore from "screens/MyProfile/store/useAffiliateStore"
import useEmailInputStore from "screens/MyProfile/screens/Affiliate/store/useEmailInputStore"
import useTelegramInputStore from "screens/MyProfile/screens/Affiliate/store/useTelegramInputStore"
import usePromotionsStore from "screens/Promotions/store/usePromotionsStore"
// import useFreeNtryPowerBallStore from "screens/FreeNtryPowerball/store/useFreeNtryPowerBallStore"
import {useMyPageStore} from "screens/MyPage/store"
import {TabPage} from "screens/MyPage/config"
import LoginService from "services/Account/Login"
import {HOME, PROMOTIONS} from "screens/NavHome/utils/config"
import navStore from "screens/NavHome/store/navStore"
import {useInquiryStore} from "screens/Home/MyPage/Screens/CustomerCenter/store"
import usePusherProvider from "provider/pusher/usePusherProvider"
import useAppProvider from "provider/useAppProvider"
import Layout from "screens/Layout"
import {ThemeContext} from "context/ThemeContext"
import {themeV1} from "themes/v1"
import {themeV2} from "themes/v2"
import {themeV3} from "themes/v3"
import LandingPage from "components/LandingPage"

function App(): JSX.Element {
  const bodyRef = useRef<HTMLDivElement>()
  const scrollTimeoutRef = useRef<NodeJS.Timeout>()
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const refreshTokenInterval = useRef<NodeJS.Timeout>()
  useAppProvider()
  usePusherProvider()
  const {handlePageTransition} = usePageTransition()
  const client = useQueryClient()

  const {
    setUserDetails,
    setAuthToken,
    authToken,
    setGetUserDetails,
    setLogout,
    expiresIn,
    setExpiresIn,
  } = useUserStore(
    store => ({
      setUserDetails: store.setUserDetails,
      setAuthToken: store.setAuthToken,
      authToken: store.authToken,
      setGetUserDetails: store.setGetUserDetails,
      setLogout: store.setLogout,
      expiresIn: store.expiresIn,
      setExpiresIn: store.setExpiresIn,
    }),
    shallow,
  )

  const setInboxUnreadCount = useInboxStore(store => store.setUnreadCount, shallow)

  const {title, setSelectedTab, setFeatureRestriction, resetProfileStore} =
    useMyProfileStore(
      store => ({
        title: store.selectedTab,
        setSelectedTab: store.setSelectedTab,
        setFeatureRestriction: store.setFeatureRestriction,
        resetProfileStore: store.resetStore,
      }),
      shallow,
    )

  const {activeTab, setActiveTab} = useMyPageStore(
    store => ({
      activeTab: store.activeTab,
      setActiveTab: store.setActiveTab,
    }),
    shallow,
  )

  const {setIsLogin} = useLoginModalStore(
    store => ({
      setIsLogin: store.setIsLogin,
    }),
    shallow,
  )

  const {
    isAffiliateEnabled,
    setIsAffiliateEnbabled,
    setAffilateStatus,
    setIsAffiliateRead,
    resetStore,
  } = useAffiliateStore(
    store => ({
      isAffiliateEnabled: store.isEnbabled,
      setIsAffiliateEnbabled: store.setIsEnbabled,
      setAffilateStatus: store.setApplicationStatus,
      setIsAffiliateRead: store.setIsRead,
      resetStore: store.resetStore,
    }),
    shallow,
  )

  const resetAffiliateEmailStore = useEmailInputStore(
    store => store.resetStore,
    shallow,
  )

  const resetAffiliateTelegramStore = useTelegramInputStore(
    store => store.resetStore,
    shallow,
  )

  const {setSelectedTitle, setSelectedIndex, setSliderIndex} = navStore(
    store => ({
      setSelectedTitle: store.setSelectedTitle,
      setSelectedIndex: store.setSelectedIndex,
      setSliderIndex: store.setSliderIndex,
    }),
    shallow,
  )

  const setHasCustomerCenterNotif = useInquiryStore(
    store => store.setHasNotification,
    shallow,
  )

  smoothscroll.polyfill()

  // Initialize Google Analytics with the appropriate configuration
  useEffect(() => {
    try {
      if (process.env.REACT_APP_GA_TAG) {
        ReactGA.initialize(process.env.REACT_APP_GA_TAG)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (isAffiliateEnabled) return

    if (title === AFFILIATE || title === AFFILIATE_REGISTER) {
      resetStore()
      resetAffiliateEmailStore()
      resetAffiliateTelegramStore()
      setSelectedTab(CHARGE_EXCHANGE)
    }
    if (activeTab === TabPage.affiliate) {
      handlePageTransition(() => {
        resetStore()
        resetAffiliateEmailStore()
        resetAffiliateTelegramStore()
        setActiveTab(TabPage.none)
      })
    }
  }, [isAffiliateEnabled, title, activeTab])

  useEffect(() => {
    usePromotionsStore.getState().refresh()

    const isPromotion = localStorage.getItem(NAV_HOME_SELECTED_PAGE) === PROMOTIONS

    if (isPromotion && authToken) {
      localStorage.removeItem(NAV_HOME_SELECTED_PAGE)
      setSliderIndex(0)
      setSelectedTitle(HOME)
      setSelectedIndex(0)
    }

    if (authToken) return setIsLogin(false)
    resetProfileStore()
    setExpiresIn(null)
    setUserDetails(null)
    window.scrollTo({top: 0})

    localStorage.removeItem(NAV_HOME_SELECTED_PAGE)
    setSliderIndex(0)
    setSelectedTitle(HOME)
    setSelectedIndex(0)
    setIsLogin(false)
  }, [authToken])

  const logout = React.useCallback(async () => {
    try {
      await LoginService.logout()
      setIsLogin(true)

      handlePageTransition(() => {
        setSliderIndex(0)
        setSelectedTitle(HOME)
        setSelectedIndex(0)
        setAuthToken(null)
        setIsLogin(false)
      })
    } catch (e) {
      setIsLogin(true)
      handlePageTransition(() => {
        setSliderIndex(0)
        setSelectedTitle(HOME)
        setSelectedIndex(0)
        setAuthToken(null)
        setIsLogin(false)
      })
    }
  }, [])

  const getUserDetails = React.useCallback(async () => {
    if (authToken) {
      try {
        const response = await servicePlayer.getDetails()
        const rData = (response as any)?.data
        setUserDetails((response as any)?.data)
        setInboxUnreadCount(response?.data?.inbox_message_unread_count)
        setFeatureRestriction(response?.data?.feature_restriction)
        setIsAffiliateEnbabled(response?.data?.affiliate?.enabled)
        setAffilateStatus(response?.data?.affiliate?.status)
        setIsAffiliateRead(response?.data?.affiliate?.is_read)
        setHasCustomerCenterNotif(response?.data?.has_inquiry_answered)
        client.invalidateQueries([BET_HISTORY_QUERY])
        client.invalidateQueries([BET_HISTORY_TOTALS_QUERY])
        client.invalidateQueries([AFFILIATE_DETAILS_QUERY])
        return (response as any)?.data
      } catch (e) {
        return e
      }
    }
  }, [authToken])

  useEffect(() => {
    setGetUserDetails(getUserDetails)
  }, [getUserDetails])

  useEffect(() => {
    setLogout(logout)
  }, [logout])

  useEffect(() => {
    if (authToken) {
      getUserDetails()
    } else {
      // exclude saved_id from getting clear
      const saved_id = localStorage.getItem("saved_id")
      const slotRecentSearches = localStorage.getItem("slot_recent_searches")
      const casinoRecentSearches = localStorage.getItem("casino_recent_searches")
      const navHomeSelectedPage = localStorage.getItem(NAV_HOME_SELECTED_PAGE)
      const slotModalCounter = localStorage.getItem(`slot_modal_counter`)
      const changeMobileNumber = localStorage.getItem("change_mobile_number")
      const isForceRefresh = localStorage.getItem("page-has-been-force-refreshed")
      const refererrCode = localStorage.getItem("REFERRAL_CODE")
      const ignoredDate = localStorage.getItem("home_banner_ignore")
      const ignoredDateV2 = localStorage.getItem("home_banner_v2_ignore")
      const ignoredDateV3 = localStorage.getItem("home_banner_v3_ignore")
      const ignoredDateV4 = localStorage.getItem("home_banner_v4_ignore")
      window.localStorage.clear()

      if (saved_id) localStorage.setItem("saved_id", saved_id)
      if (slotRecentSearches)
        localStorage.setItem("slot_recent_searches", slotRecentSearches)
      if (navHomeSelectedPage)
        localStorage.setItem(NAV_HOME_SELECTED_PAGE, navHomeSelectedPage)
      if (slotModalCounter)
        localStorage.setItem("slot_modal_counter", slotModalCounter)
      if (casinoRecentSearches)
        localStorage.setItem("casino_recent_searches", casinoRecentSearches)
      if (changeMobileNumber)
        localStorage.setItem("change_mobile_number", changeMobileNumber)
      if (isForceRefresh)
        localStorage.setItem("page-has-been-force-refreshed", isForceRefresh)
      if (refererrCode) localStorage.setItem("REFERRAL_CODE", refererrCode)
      if (ignoredDate) localStorage.setItem("home_banner_ignore", ignoredDate)
      if (ignoredDateV2) localStorage.setItem("home_banner_v2_ignore", ignoredDateV2)
      if (ignoredDateV3) localStorage.setItem("home_banner_v3_ignore", ignoredDateV3)
      if (ignoredDateV4) localStorage.setItem("home_banner_v4_ignore", ignoredDateV4)

      // const freeNtry = useFreeNtryPowerBallStore.getState()
      // freeNtry.setCanPlay(true)
    }
  }, [authToken])

  /** REFRESH LOGIN TOKEN */
  useEffect(() => {
    const authToken = useUserStore.getState()
    if (!authToken) return
    if (expiresIn) {
      clearInterval(refreshTokenInterval.current)
      refreshTokenInterval.current = setInterval(() => {
        const now = new Date()
        const expiryMinsBefore = subMinutes(new Date(expiresIn), 15)

        if (isAfter(now, expiryMinsBefore)) {
          clearInterval(refreshTokenInterval.current)
          refreshToken()
        } else if (isAfter(now, new Date(expiresIn))) {
          clearInterval(refreshTokenInterval.current)
          logout()
        }
      }, 1000)
    }
  }, [expiresIn])

  const forceRefresh = e => {
    console.log("error -", e)
    if (includes(e.message, "Loading chunk ")) {
      console.log("error on chunk - e -", e)
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener("error", forceRefresh)
    const body = document.getElementById("body") as HTMLDivElement
    if (body) {
      body.classList.add("loaded")
      bodyRef.current = body
    }
  }, [])

  useEffect(() => {
    if (!bodyRef?.current || !isWindows) return
    const elem = bodyRef?.current

    elem.classList.add("customScroll")

    elem.onscroll = e => {
      setIsScrolling(true)
      clearTimeout(scrollTimeoutRef.current)
      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false)
      }, 500)
    }
  }, [bodyRef?.current])

  useEffect(() => {
    if (!bodyRef?.current) return

    const elem = bodyRef?.current
    if (isScrolling) elem.classList.add("fadeInScroll")
    else elem.classList.remove("fadeInScroll")
  }, [isScrolling])

  //
  // theme change logic
  //
  const [isLoading, setIsLoading] = useState(true)
  const {
    setTheme,
    setTopDesktopBanner,
    setBottomDesktopBanner,
    setTopMobileBanner,
    setBottomMobileBanner,
  } = useContext(ThemeContext)

  useEffect(() => {
    themeService.getThemeDetails().then(res => {
      const data = res?.data?.data
      setTheme(
        data.main_theme_type === "themeV1"
          ? themeV1
          : data.main_theme_type === "themeV2"
          ? themeV2
          : themeV3,
      )
      setTopDesktopBanner(data?.top_banner_desktop)
      setBottomDesktopBanner(data?.bottom_banner_desktop)
      setTopMobileBanner(data?.top_banner_mobile)
      setBottomMobileBanner(data?.bottom_banner_mobile)
      setIsLoading(false)
    })
  }, [])

  return isLoading ? <LandingPage /> : <Layout />
}

export default memo(App)
