export const CHARGE_EXCHANGE = "충전/환전"
export const MY_INFO = "나의정보"
export const BET_HISTORY = "베팅내역"
export const RECHARGE_HISTORY = "충환전내역"
export const MONEY_HISTORY = "페이사용내역"
export const REWARD_HISTORY = "마일리지"
export const CHANGE_BANK = "계좌번호변경"
export const REGISTER_BANK = "계좌번호등록"
export const CHANGE_PASSWORD = "비밀번호변경"
export const MOBILE_NUMBER = "휴대폰 번호 변경"
export const SMS_NOTIF = "SMS 알림 설정"
export const SELF_RESTRICT = "이용금지설정"
export const CUSTOMER_SUPPORT = "고객지원"
export const INBOX = "편지함"
export const AFFILIATE = "파트너 프로그램"
export const AFFILIATE_REGISTER = "파트너쉽 신청 페이지"
export const SIGN_OUT = "로그아웃"

export const BACK_LABEL = "이전 페이지"
export const REWARD_POINTS = "마일리지"
export const BALANCE = "보유머니"

export const TODAY = "오늘"
export const THREE_DAYS_AGO = "3 일전"
export const WEEK = "이번주"
export const MONTH = "이번달"

export const CONFIRM = "적용"
export const CANCEL = "취소"
export const SAVE = "저장하기"

export const INBOX_HEADER_MSG = "3개월 이전의 자료는\n자동 삭제됩니다."
export const DELETE_ALL = "전체삭제"
export const READ_ALL = "모두읽기"
export const SUBJECT = "제목"
export const READ = "확인"
export const UNREAD = "미확인"
export const INBOX_DETAIL_HEADER = "편지함"
export const DATE_CREATED = "작성일"
export const DATA_CONFIRMED = "확인 시간"
export const INQUIRY = "문의 내용"
export const LETTER_CONTENT = "편지 내용"
export const EMPTY_INBOX = "편지함이 비어 있습니다."
export const CONFIRM_DELETE = "전체 메세지를 삭제 하시겠습니까?"
export const CONFIRM_READ = "모든 메시지를 읽으시겠습니까?"

export const ID = "아이디"
export const PASSWORD = "비밀번호"
export const NAME = "이름"
export const MOBILE_PHONE_NUMBER = "휴대폰번호"
export const BANK = "은행"
export const ACCOUNT_NUMBER = "계좌번호"
export const REFERRER = "추천인"
export const REGISTERED_AT = "가입일시"

export const SMS_NOTIF_HEADER = "수신 알림을 설정하실 수 있습니다."
export const DEPOSIT_NOTIF = "입금계좌 문자 알림"
export const SPORTS_NOTIF = "프리매치 스포츠 결과 문자 알림"
export const PROMOTION_NOTIF = "이지벳 프로모션 문자 알림"
export const NOTIF_NOTE1 = "라이브 스포츠 결과 알림은 게임의 특성상"
export const NOTIF_NOTE2 = "SMS 알림 서비스를 제공하지 않습니다."
export const SMS_NOTIF_ALERT = "SMS 알림 설정이 완료 되었습니다."

export const PROHIBIT_HEADER =
  "정해진 기간 동안 해당 계정에\n접속 금지 설정을 하실 수 있습니다."
export const PROHIBIT_SUB_HEADER =
  "이용금지 설정 서비스를 신청하시기 전\n한 번 더 신중히 고민하시기 바랍니다."
export const PROHIBIT_LINE =
  "이용 금지 적용 시 당일 자정 ( 00:00:00 ) 부터\n해당 계정에 접속이 불가능하며,\n적용 취소 의사가 있는 경우에는\n라이브챗으로 문의하여 주시기 바랍니다."
export const PROHIBIT_IMMIDIATE_LINE =
  "즉각적인 정지 적용 시 즉시 해당 계정으로 접속이\n불가능하며, 적용 취소 의사가 있는 경우에는\n라이브 챗으로 문의해 주시기 바랍니다."
export const DURATION = "기간선택"
export const PROHIBIT_PLACEHOLDER = "기간을 선택해 주세요"
export const PROHIBIT_DEACT_MSG_TITLE = "이지벳 이용 금지 설정이 적용 되었습니다."
export const PROHIBIT_DEACT_MSG = "신청 당일 23:59:59 전까지\n적용 취소 가능합니다."
export const PROHIBIT_ACT_MSG = "이지벳 이용 금지 적용 신청이\n취소 되었습니다."
export const PROHIBIT_ACT_HEADER = "현재 :player_name 회원님은"
export const PROHIBIT_ACT_NOTE =
  "이용 금지 적용시 당일 자정 ( 23:59:59 ) 부터 해당 계정에 접속이 불가능 하며\n적용 취소 의사가 있을 경우, 신청 당일 자정 전까지 취소가 가능합니다.\n이용 금지 적용 기간중 계정 복구를 위해서는 라이브챗으로 문의 하시기 바랍니다."
export const PROHIBIT_ACT_NOTE_MOBILE =
  "이용 금지 적용시 당일 자정 ( 23:59:59 ) 부터\n해당 계정에 접속이 불가능 하며\n적용 취소 의사가 있을 경우,\n신청 당일 자정 전까지 취소가 가능합니다.\n이용 금지 적용 기간중 계정 복구를 위해서는\n라이브챗으로 문의 하시기 바랍니다."
export const PROHIBIT_ACT_BAN_FOOTER = "이용 금지 적용을 신청 하셨습니다."
export const PROHIBIT_DEACTIVATE = "이용 금지 적용 신청"
export const PROHIBIT_ACTIVATE = "이용 금지 적용 신청 취소"

export const INBOX_QUERY = "PLAYER_INBOX_QUERY"
export const SMS_SETTINGS_QUERY = "SMS_SETTINGS_QUERY"
export const SELF_RESRTRICT_QUERY = "SELF_RESRTRICT_QUERY"
export const REWARD_HISTORY_QUERY = "REWARD_HISTORY_QUERY"
export const GAME_FILTERS_QUERY = "GAME_FILTERS_QUERY"

export const CHANGE_MOBILE_HEADER =
  "휴대폰 번호 변경 페이지 입니다.\n새로운 휴대폰 번호를 입력해 주시기 바랍니다."
export const CHANGE_MOBILE_LABEL = "새 전화번호"
export const CHANGE_MOBILE_LABEL_HELPER = "휴대폰 번호 변경"
export const CHANGE_MOBILE_PLACEHOLDER = "숫자만 입력해 주세요"
export const CHANGE_MOBILE_BUTTON = "변경하기"
export const CHANGE_MOBILE_SAVED_LABEL = "휴대폰 번호 변경이 완료 되었습니다."
export const CHANGE_MOBILE_SAVED_SUB_LABEL =
  "요청하신휴대폰 번호변경이 완료 되었으며\n변경된 번호는 마이패이지에서 확인 가능합니다.\n이용해 주셔서 감사합니다."

export const CHANGE_PASSWORD_HEADER =
  "비밀번호 변경 페이지 입니다.\n새로운 비밀번호와 문자 인증이 필요합니다."
export const MOBILE_LABEL = "전화번호"
export const CHANGE_PASSWORD_SAVED_LABEL =
  "회원님의 소중한 개인정보 보호를 위해\n정기적인 비밀번호 변경을 권장합니다."
export const CHANGE_PASSWORD_SAVED_SUB_LABEL =
  "요청하신 비밀번호 변경이 완료 되었습니다.\n확인 버튼 클릭시 로그인 화면으로 이동합니다."
export const INPUT_PASSWORD_PLACEHOLDER = "8~20 자 영문, 숫자, 특수문자 사용"
export const INPUT_CONFIRM_PASSWORD_PLACEHOLDER = "새 비밀번호 재확인"
export const NEW_PASSWORD = "새 비밀번호"
export const CONFIRM_NEW_PASSWORD = "새 비밀번호 재확인"
export const REQUIRED_FIELD = "필수 정보입니다."
export const PASSWORD_MATCH = "입력하신 비밀번호와 일치합니다."
export const PASSWORD_NOT_MATCH = "비밀번호가 일치 하지 않습니다."

export const BANKLIST_QUERY = "BANKLIST_QUERY"
export const BANK_ACCOUNT_HISTORY_QUERY = "BANK_ACCOUNT_HISTORY_QUERY"
export const BANK_ACCOUNT_STATUS_QUERY = "BANK_ACCOUNT_STATUS_QUERY"
export const BANK_DEPOSIT_REQUEST_QUERY = "BANK_DEPOSIT_REQUEST_QUERY"
export const BANK_WITHDRAW_REQUEST_QUERY = "BANK_WITHDRAW_REQUEST_QUERY"

export const CHANGE_BANK_HEADER =
  "계좌번호 변경 페이지입니다.\n새로운 계좌번호와 문자 인증이 필요합니다."
export const REGISTER_BANK_HEADER =
  "이지벳의 유료 컨텐츠를 이용하기 위해서\n계좌번호 등록은 필수사항입니다."
export const CHANGE_BANK_SUB_HEADER =
  "예금주명은 회원가입시 등록한 이름과 일치해야 합니다."
export const REGISTER_BANK_TIP =
  "Tip.회원 등급이 올라 갈수록 안전한 충전 계좌가 발급됩니다."
export const BANK_SELECTOR_PLACEHOLDER = "은행을 선택해 주세요"
export const NEW_ACCOUNT_NUMBER = "새로운 계좌번호"
export const NEW_ACCOUNT_PLACEHOLDER = "새로운 계좌번호를 입력해 주세요"
export const VALID_ACCOUNT_NUMBER = "올바른 고객 번호를 입력하십시오."
export const INVALID_ACCOUNT_NUMBER = "잘못된 은행 계좌 번호."
export const ACCOUNT_NUMBER_HELPER = "기존 계좌 번호 뒤 5 자리 :"
export const BANK_NAME_HELPER = "기존 등록 은행 :"
export const PENDING_APPROVAL = "승인대기중"
export const CHANGE_BANK_MAX_LIMIT_ERROR =
  "관리자 취소 및 정보 불일치로 \n인증 취소 되었습니다. \n일 3회 등록/변경 제한으로 \n10시간 이후 \n재신청 하시기 바랍니다."
export const CHANGE_BANK_REQUEST_SENT_HEADER =
  "이지벳 계좌번호 변경이 요청 되었습니다."
export const CHANGE_BANK_REQUEST_SENT_NOTES =
  "인증을 위해 운영지원팀 에서 확인중에 있으며 소요 시간은 10분 이내에 완료됩니다. 인증 완료 시 편지함 및 나의 정보 페이지 에서  확인 하실 수 있습니다. 이용해 주셔서 감사합니다."
export const EXISTING_INFO = "기존 정보"
export const CHANGE_REQUEST_INFO = "변경 요청 정보"
export const REGISTER_BANK_ACCOUNT_HELPER =
  "예금주명은 가입시 등록한 이름과 동일해야 함"
export const REGISTER = "등록하기"
export const REGISTER_HEADER_SUCCESS = "계좌 등록 요청 정보"
export const REGISTER_BANK_REGQUEST_SENT_HEADER =
  "이지벳 계좌번호 등록이 요청 되었습니다"
export const REGISTER_BANK_REGQUEST_SENT_NOTES =
  "인증을 위해 운영지원팀 에서 확인중에 있으며 소요 시간은 10분 이내에 완료됩니다. 인증 완료 시 편지함 및 나의 정보 페이지 에서 확인 하실 수 있습니다. 이용해 주셔서 감사합니다."

export const DEPOSIT_APPLICATION = "충전신청"
export const WITHDRAW_APPLICATION = "환전신청"

export const CUSTOMER_SUPPORT_DETAIL_HEADER = "고객 센터 문의 내역"
export const CONTACT_TYPE = "문의 유형"
export const REGISTRATION_TIME = "등록 시간"
export const ANSWER_STATUS = "답변 결과"
export const ANSWERED = "답변완료"
export const UNANSWERED = "미답변"
export const ANSWER = "답변"
export const CHARGING = "충전"
export const CANCEL_CHARGING = "충전 취소"
export const CANCEL_WITHDRAW = "환전 취소"
export const WITHDRAW = "환전 신청"
export const PENDING_WITHDRAW_ALERT =
  "현재 환전 진행중에 있습니다.\n환전 완료후 변경 신청 \n해주시기 바랍니다."
export const PENDING_DEPOSIT_ALERT = `현재 충전 진행 중에 있습니다.\n충전 완료 후 변경 신청해\n주시기 바랍니다.`
export const SUBJECT_HELPER = "1~20 자"
export const MESSAGE_HELPER = "1~255 자"
export const SEND = "보내기"

export const VALID_EMAIL = "입력한 전자 메일로 일회용 암호가 전송됩니다."
export const INVALID_EMAIL = "유효한 이메일 형식을 입력하십시오."
export const TAKEN_EMAIL = "이 이메일 이미 사용 중입니다."

export const VALID_TELEGRAM = "입력한 사용자 이름은 지원 팀과의 통신에 사용됩니다."
export const TAKEN_TELEGRAM = "이 텔레그램은 이미 사용 중입니다."

export const EMAIL_OTP_FOOTER_MSG =
  "이미 가입된 이메일 이거나\n인증번호를 받을수 없습니다."
export const MOBILE_OTP_FOOTER_MSG =
  "이미 가입된 번호 이거나, 가상 전화번호는\n인증번호를 받을수 없습니다."

export const HAS_PENDING_OTP = "현재 진행 중인 OTP 인증이 있습니다."
export const MAX_OTP_REQUEST =
  "인증번호 요청 10회 초과\n60분 후 재요청 해주시기 바랍니다."

export const DISABLED_DEPOSIT_LABEL = "점검중입니다."
export const DISABLED_DEPOSIT_TEXT = "잠시 뒤에 신청하여 주십시오."
export const DISABLED_DEPOSIT = {
  label: DISABLED_DEPOSIT_LABEL,
  text: DISABLED_DEPOSIT_TEXT,
}
