import React, {FC, useMemo, memo, Suspense} from "react"
import {useWindowSize} from "react-use"
import {lazyWithRetry} from "utils/lazyWithRetry"
import Mobile from "./Mobile"
import Tablet from "./Tablet"

interface iMobileView {
  className?: string
}
const MobileView: FC<iMobileView> = ({className}): JSX.Element => {
  const {width} = useWindowSize()

  const isTablet = useMemo(() => {
    return width > 673
  }, [width])

  if (isTablet)
    return (
      <Suspense fallback={<></>}>
        <Tablet />
      </Suspense>
    )

  return (
    <Suspense fallback={<></>}>
      <Mobile />
    </Suspense>
  )
}

export default memo(MobileView)
