import numeral from "numeral"
import flatten from "lodash.flatten"
import sum from "lodash/sum"
import {formatAmount} from "utils/helpers"

export function amount(value: number | string): null | string {
  if (value === undefined || value === "" || value === null) {
    return null
  }

  return numeral(String(Math.trunc(Number(value)))).format("0,0")
}

export function getPercentage(value: number): number {
  return value / 100
}

export function getTotalGameBonus(bonusValues: number[]): string | null {
  const values = flatten(bonusValues)
  const total = values[values.length - 1]

  if (total === 0 || isNaN(total)) {
    return null
  }

  return numeral(Math.trunc(total)).format("0,0")
}

export function getTotalPaylines(paylineValues: number[]): string | null {
  const values = flatten(paylineValues)
  const total = values?.reduce((a: number, b: number) => a + b, 0)

  if (total === 0 || isNaN(total)) {
    return null
  }

  return numeral(Math.trunc(total)).format("0,0")
}

export function getTotalArray(values: number[]): number | null {
  if (isNaN(values[0])) {
    return null
  }

  return sum(values)
}

export function getAbsoluteVal(value: number): number | null {
  return Math.abs(Math.trunc(value))
}

export function formatDisplayAbsoluteAmount(value: string): string | number {
  const number = Number(formatAmount(value))
  const absoluteVal = getAbsoluteVal(number)
  const display = formatAmount(absoluteVal.toString(), true)
  return display
}

export function formatDisplayAmount(value: string, type: string): string {
  const formatted = formatDisplayAbsoluteAmount(value)

  if (type === "deposit") {
    return `+ ${formatted}`
  } else if (type === "withdraw") {
    return `- ${formatted}`
  } else {
    return `${formatted}`
  }
}

export function formatValueToKoreanAbbreviation(value: number) {
  let suffixes = ["십원", "백원", "천원", "만원", "만원", "만원", "만원", "억원"]
  if (value < 100) {
    return Math.floor(value / 10).toFixed() + "십원"
  } else if (value < 1000) {
    return Math.floor(value / 100).toFixed() + "백원"
  } else if (value < 10000) {
    return Math.floor(value / 1000).toFixed() + "천원"
  } else if (value < 100000000) {
    return Math.floor(value / 10000).toFixed() + "만원"
  } else if (value < 1000000000) {
    return Math.floor(value / 100000000).toFixed() + "억원"
  }
}
