import create from "zustand"
import {CHARGE_EXCHANGE} from "screens/MyProfile/constants"

interface iMyProfileStore {
  selectedTab: string
  alertMessage: string
  featureRestriction: string[]
  sideBarRef: React.MutableRefObject<HTMLDivElement>
  bodyRef: React.MutableRefObject<HTMLDivElement>
  sideBarHeight: number
  contentHeight: number
  setSideBarHeight: (value: number) => void
  setContentHeight: (value: number) => void
  setSelectedTab: (value: string) => void
  setAlertMessage: (value: string) => void
  setFeatureRestriction: (value: string[]) => void
  setSideBarRef: (ref: React.MutableRefObject<HTMLDivElement>) => void
  setBodyRef: (ref: React.MutableRefObject<HTMLDivElement>) => void
  resetStore: () => void
}

const useMyProfileStore = create<iMyProfileStore>((set, get) => ({
  selectedTab: CHARGE_EXCHANGE,
  alertMessage: "",
  featureRestriction: [],
  sideBarRef: null,
  bodyRef: null,
  sideBarHeight: 0,
  contentHeight: 0,
  setSideBarHeight: sideBarHeight => set({sideBarHeight}),
  setContentHeight: contentHeight => set({contentHeight}),
  setSelectedTab: selectedTab => set({selectedTab}),
  setAlertMessage: alertMessage => set({alertMessage}),
  setFeatureRestriction: featureRestriction => set({featureRestriction}),
  setSideBarRef: sideBarRef => set({sideBarRef}),
  setBodyRef: bodyRef => set({bodyRef}),
  resetStore: () => {
    set({
      selectedTab: CHARGE_EXCHANGE,
      alertMessage: "",
      featureRestriction: [],
    })
  },
}))

export default useMyProfileStore
