// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"
/**
 * Utils
 */
import {colorPalette as $cp} from "utils/theme/colors"
import {isIOS, isFirefox} from "react-device-detect"

interface iCheckbox {
  keyName: string
  checked: boolean
  label?: string
  labelSize?: number
  labelWeight?: number
  onChange: () => void
  children?: React.ReactNode
}

function Checkbox({
  keyName,
  checked,
  label = "",
  onChange,
  labelSize = 12,
  labelWeight = 300,
  children,
}: iCheckbox): JSX.Element {
  return (
    <div className={`${keyName}-checkbox-wrapper`}>
      <div className={`${keyName}-checkbox-container`}>
        <div className={`${keyName}-checkbox-container-input`}>
          <div className={`${keyName}-checkbox-bordered`}>
            <div className={`${keyName}-checkbox-body`}>
              <div>
                <span>
                  <label style={{display: "flex", alignItems: "center"}}>
                    <div className={`${keyName}-checkbox-content`}>
                      <input
                        type="checkbox"
                        className={`${keyName}-checkbox-input`}
                        onChange={onChange}
                        checked={checked}
                      />
                      <div className={`${keyName}-checkbox-styled`}>
                        <svg viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </div>
                    </div>
                    {children ? (
                      children
                    ) : (
                      <div className={`${keyName}-checkbox-label`}>{label}</div>
                    )}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .${keyName}-checkbox-wrapper {
          width: auto;
          height: 100%;
          position: relative;
          align-items: center;
          padding: 0px;
          margin: 0px;
        }
        .${keyName}-checkbox-wrapper:last-child {
          margin-bottom: 0px;
        }
        .${keyName}-checkbox-container {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0px;
          box-sizing: border-box;
          transition: margin-bottom ${0.6}s ease-in-out;
        }
        .${keyName}-checkbox-container-input {
          flex: 1 1 0%;
        }
        .${keyName}-checkbox-bordered {
          position: relative;
          padding: 0px;
          margin: 0px;
          width: 100%;
          border-radius: 4px;
          transition: all 0.6s ease-in-out 0s;
          box-shadow: transparent 0px 0px 0px 1px inset;
        }
        .${keyName}-checkbox-body {
          display: flex;
          justify-content: space-between;
          padding: 0px;
          margin: 0px;
        }

        .${keyName}-checkbox-content {
          display: inline-block;
          vertical-align: middle;
        }
        .${keyName}-checkbox-input {
          border: 0;
          clip: rect(0 0 0 0);
          clippath: inset(50%);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          white-space: nowrap;
          width: 1px;
        }
        .${keyName}-checkbox-styled {
          width: 20px;
          height: 20px;
          background: ${checked ? $cp.aquablue : "#FFFFFF"};
          box-shadow: inset 0 0 0 1px ${checked ? $cp.aquablue : $cp.grey2};
          border-radius: 2px;
          transition: all ${0.3}s ease-in-out;
        }

        .${keyName}-checkbox-styled svg {
          visibility: ${checked ? "visible" : "hidden"};
          fill: none;
          stroke: white;
          stroke-width: 2px;
        }

        .${keyName}-checkbox-label {
          font-size: ${labelSize}px;
          font-weight: ${labelWeight};
          color: ${$cp.grey2};
          font-family: "AppleSDGothicNeo-Regular";
          padding: ${!isIOS && isFirefox ? "1px 10px 4px" : "1px 10px 0"};
        }

        .${keyName}-checkbox-label a {
          font-size: 12px;
          color: ${$cp.grey2};
        }
      `}</style>
    </div>
  )
}

export default Checkbox
