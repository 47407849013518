import React, {ReactNode, useEffect, useState} from "react"
import ReactModal from "react-modal"
import {useWindowSize} from "react-use"
import cx from "classnames"
import s from "./modalconfirm.module.scss"

interface iAlertModal {
  message?: string
  confirm?: () => void
  cancel?: () => void
  children?: ReactNode
  appElement?: string
  confirmLabel?: string
  hideCancelButton?: boolean
}

function AlertModal({
  confirm,
  cancel,
  message,
  children,
  appElement,
  confirmLabel,
  hideCancelButton = false,
}: iAlertModal): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const {height} = useWindowSize()
  useEffect(() => {
    document.body.style.overflow = `hidden`
    document.body.style.height = `100vh`

    setTimeout(() => {
      setIsOpen(true)
    }, 50)

    return () => {
      document.body.removeAttribute("style")
    }
  }, [])

  const handleConfirm = (): void => {
    if (!isOpen) return
    setIsOpen(false)
    setTimeout(() => {
      confirm()
      cancel()
    }, 300)
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setTimeout(() => {
      cancel()
    }, 300)
  }

  if (children !== undefined) {
    return <ReactModal isOpen>{children}</ReactModal>
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={s.container}
      id={`confirm-modal`}
      isOpen
      style={{
        content: {
          minHeight: `${height}px`,
        },
      }}
      overlayClassName={cx(s.overlay, {
        [s.show]: isOpen,
      })}
    >
      <div
        className={cx(s.modalWrapper, {
          [s.show]: isOpen,
        })}
      >
        <div className={s.modalMessage}>
          <pre>{message}</pre>
        </div>
        <div className={s.btnGroup}>
          <button onClick={() => handleConfirm()} className={s.modalConfirm}>
            {confirmLabel ? confirmLabel : `확인`}
          </button>
          {!hideCancelButton && (
            <button onClick={() => handleClose()} className={s.cancel}>
              {`취소`}
            </button>
          )}
        </div>
      </div>
    </ReactModal>
  )
}

export default AlertModal
