// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {FC, Suspense} from "react"

import Provider from "screens/Account/Register/Context/Provider"
import {lazyWithRetry} from "utils/lazyWithRetry"
import ModalView from "components/RegisterButton/Layout"

const MobileView = lazyWithRetry(
  () => import(`screens/Account/Register/Context/View`),
)

interface iRegister {
  isModal?: boolean
}
const Register: FC<iRegister> = ({isModal}): JSX.Element => (
  <Provider>
    {!isModal && (
      <Suspense fallback={<></>}>
        <MobileView />
      </Suspense>
    )}
    {isModal && (
      <Suspense fallback={<></>}>
        <ModalView />
      </Suspense>
    )}
  </Provider>
)

export default Register
