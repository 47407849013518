import {parseImageUrl} from "utils/helpers"

const DefaultUrl = "/assets/banner/register/default.webp"
const SportgUrl = "/assets/banner/register/sports.webp"
const EvolutionUrl = "/assets/banner/register/casino.webp"
// const EosUrl = "/assets/banner/register/eos.webp"
// const Bet365Url = "/assets/banner/register/bet365.webp"

//Mobile
const SmallDefaultUrl = "/assets/banner/register/small/default.webp"
const SmallSportgUrl = "/assets/banner/register/small/sports.webp"
const SmallEvolutionUrl = "/assets/banner/register/small/casino.webp"
// const SmallEosUrl = "/assets/banner/register/small/eos.webp"
// const SmallBet365Url = "/assets/banner/register/small/bet365.webp"

export const POPUP_BANNER_TYPES = {
  DAILY_BONUS: "daily_bonus",
  FIRST_DEPOSIT: "first_deposit",
  MULTIBET_REFUND: "multibet_refund",
  CASH_OUT: "cash_out",
  LVL_UP_REWARDS: "lvl_up_rewards",
}

export const REGISTER_BANNERS = [
  {
    name: "default",
    assets: {
      l: parseImageUrl(DefaultUrl, {width: 500}),
      s: parseImageUrl(SmallDefaultUrl, {width: 150}),
    },
    description: "특별함에 사로잡히다",
  },
  {
    name: "sports",
    assets: {
      l: parseImageUrl(SportgUrl, {width: 500}),
      s: parseImageUrl(SmallSportgUrl, {width: 150}),
    },
    description: "유니크한 스포츠북 디자인",
  },
  {
    name: "evolution",
    assets: {
      l: parseImageUrl(EvolutionUrl, {width: 500}),
      s: parseImageUrl(SmallEvolutionUrl, {width: 150}),
    },
    description: "세계적 수준의 라이브 카지노 공급업체",
  },
  // {
  //   name: "eos",
  //   assets: {
  //     l: EosUrl,
  //     s: SmallEosUrl,
  //   },
  //   description: "선택할 수 있는 5가지 다른 라운드입니다.",
  // },
  // {
  //   name: "bet365",
  //   assets: {
  //     l: Bet365Url,
  //     s: SmallBet365Url,
  //   },
  //   description: "높은 주파수와 접근성",
  // },
]
