import React from "react"
import {THEME2} from "screens/Home/MainV3/assets"
import s from "../layout/Footer/desktopFooter.module.scss"
import {LogoIconColored} from "components/SideBarButton/icons/LogoIconColored"

export const themeV2 = {
  colors: {
    background: "#00B2FF",
    footerBackground: "#fff",
    bodyBackground: "#fff",
    text: "#000",
    bulletBackground: "#00B2FF",
    loginActive: "#00B2FF",
    expandIconBackground: "#000",
    expandBgColor: "#fff",
    borderColor: "#fff",
    buttonBackground: "#fff",
    buttonTextColor: "#000",
    buttonActiveTextColor: "#00B2FF",
    buttonActivebottomLine:
      "linear-gradient(to right,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.2) 10%,#8DE4FF 50%,rgba(255, 255, 255, 0.2) 90%,rgba(255, 255, 255, 0) 100%)",
    headerHoverColor: "#8DE4FF",
    headerActiveColor: "#fff",
    carouselArrowsColor: "#00B2FF",
    footerIconsColor: "rgb(25, 180, 240)",
    footerLanguageSwitcherBG: "#f0f0f0",
    footerLanguageSwitcherBGActive: "#00acee",

    sidebarBG: "#fff",
    sidebarIconColor: "rgb(0, 172, 238)",
    sidebarIconBG: "#fff",
    sidebarText_arrowsColor: "#777777",
    sidebarActiveRowColor: "#00acee",
    sidebarActiveText_arrowsColor: "#fff",
    bordersColor: "#e6e6e6",

    // ...other colors
  },
  images: {
    carousel: [
      {
        src: THEME2.SPORTS,
        caption: "SPORTS PREMATCH",
        path: "pre-match-sports",
      },
      {
        src: THEME2.SLOTS,
        caption: "Slots",
        path: "slots",
      },
      {
        src: THEME2.SKYPARK,
        caption: "SKY PARK",
        path: "skypark",
      },
      {
        src: THEME2.CASINO,
        caption: "Casion",
        path: "casino",
      },
      {
        src: THEME2.EVENTS,
        caption: "Events",
        path: "gift",
      },
    ],
    banners: {
      top_banner_desktop: {
        src: THEME2.TOP_BANNER,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_desktop: {
        src: THEME2.BOT_BANNER,
        caption: "Live Casino",
        path: "casino",
      },
      top_banner_mobile: {
        src: THEME2.TOP_BANNER_M,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_mobile: {
        src: THEME2.BOT_BANNER_M,
        caption: "Live Casino",
        path: "casino",
      },
    },
    footerLogo: <LogoIconColored className={s.logoNameIcon} />,
    // ...other images
  },
}
