import React, {FC} from "react"
import cx from "classnames"
import s from "screens/MyProfile/components/DateRangePicker/Button/button.module.scss"
interface iButton {
  type: string
  label: string
  handleClick: () => void
  className?: string
  Icon?: React.JSX.Element
}

const Button: FC<iButton> = ({className, type, label, handleClick, Icon}) => {
  const onClick = () => {
    if (type !== "disabled") handleClick()
  }

  return (
    <button
      className={cx(s.button, className, {
        [s.confirm]: type === "confirm",
        [s.cancel]: type === "cancel",
        [s.delete]: type === "delete",
        [s.read]: type === "read",
        [s.disabled]: type === "disabled",
      })}
      onClick={() => onClick()}
    >
      {Icon && Icon}
      <span>{label}</span>
    </button>
  )
}

export default Button
