import React from "react"
import {THEME1} from "screens/Home/MainV3/assets"

import {LogoIcon} from "components/SideBarButton/icons/LogoIcon"
import s from "../layout/Footer/desktopFooter.module.scss"

export const themeV1 = {
  colors: {
    background: "#000",
    footerBackground: "#000",
    bodyBackground: "#000",
    text: "#fff",
    bulletBackground: "#ff9d00",
    loginActive: "#ff9d00",
    expandIconBackground: "#fff",
    expandBgColor: "#000",
    borderColor: "#fff",
    buttonBackground: "#fff",
    buttonTextColor: "#000",
    buttonActiveTextColor: "#ff9d00",
    buttonActivebottomLine:
      "linear-gradient(to right,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.2) 10%,#ff9d00 50%,rgba(255, 255, 255, 0.2) 90%,rgba(255, 255, 255, 0) 100%)",
    headerHoverColor: "#ff9d00",
    headerActiveColor: "#ff9d00",
    carouselArrowsColor: "#fff",
    footerIconsColor: "#fff",
    footerLanguageSwitcherBG: "#595959",
    footerLanguageSwitcherBGActive: "#CDCDCD",

    sidebarBG: "#161616",
    sidebarIconColor: "#FF9D00",
    sidebarIconBG: "#222222",
    sidebarText_arrowsColor: "#FFFFFF",
    sidebarActiveRowColor: "#FF9D00",
    sidebarActiveText_arrowsColor: "#000",
    bordersColor: "#444447",

    // ...other colors
  },
  images: {
    carousel: [
      {
        src: THEME1.SPORTS,
        caption: "SPORTS PREMATCH",
        path: "pre-match-sports",
      },
      {
        src: THEME1.SLOTS,
        caption: "Slots",
        path: "slots",
      },
      {
        src: THEME1.SKYPARK,
        caption: "SKY PARK",
        path: "skypark",
      },
      {
        src: THEME1.CASINO,
        caption: "Casion",
        path: "casino",
      },
      {
        src: THEME1.EVENTS,
        caption: "Events",
        path: "gift",
      },
    ],
    banners: {
      top_banner_desktop: {
        src: THEME1.TOP_BANNER,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_desktop: {
        src: THEME1.BOT_BANNER,
        caption: "Live Casino",
        path: "casino",
      },
      top_banner_mobile: {
        src: THEME1.TOP_BANNER_M,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_mobile: {
        src: THEME1.BOT_BANNER_M,
        caption: "Live Casino",
        path: "casino",
      },
    },
    footerLogo: <LogoIcon className={s.logoNameIcon} />,
    // ...other images
  },
}
