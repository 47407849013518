/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import {isIOS} from "react-device-detect"
import Fade from "react-reveal/Fade"
/**
 * Component
 */
import {IconExclamation} from "components/Icons/Forms"
import Loader from "components/Loader"
/**
 * Service
 */
import service from "services/Account/Register"
/**
 * Hooks
 */
import {useLocalStorage} from "utils/hooks/useLocalStorage"
/**
 * Styles
 */
import s from "./username.module.css"
/**
 * Utils
 */
import {tabElement} from "utils/helpers"

interface IFormUsernameInput {
  delay?: number
  category: string
  label: string
  stateValue?: string
  defaultValue?: string
  labelHelper?: string
  type?: string
  placeholder?: string
  errors?: any
  onChange?: any
  disabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  setValidUsername?: (validValue: boolean) => void
  register: any
}

const FormInput = ({
  delay, // from props
  label,
  category,
  placeholder,
  labelHelper,
  errors,
  stateValue,
  onChange,
  register,
  disabled = false,
  onFocus,
  onBlur,
  setValidUsername,
}: IFormUsernameInput): JSX.Element => {
  const [isFocused, setFocus] = useState<boolean>(false)
  const [usernameValidLocal, setValidUsernameLocal] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [apiError, setApiError] = useState<string>("")
  const [delayTime, setDelayTime] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usernameExistCount, setUsernameExistCount] = useLocalStorage(
    "usernameExistCount",
    0,
  )

  const isFindPassword = category === "FORGOT_PASSWORD"
  const isLogin = category === "LOGIN"
  const isRegister = category === "REGISTER_PLAYER"

  // Errors
  const ErrorRequired = errors !== undefined
  const FocusErrorRequired = isFocused && ErrorRequired
  const NotFocusErrorRequired = !isFocused && ErrorRequired
  const HasApiError = apiError !== ""
  const HasNoError = errors === undefined && apiError === "" && usernameValidLocal
  const HasNoErrorLogin =
    !isFocused &&
    errors === undefined &&
    apiError === "" &&
    isLogin &&
    stateValue !== ""

  function delayCall(remainingTime: number) {
    return remainingTime
  }

  async function checkUsername(): Promise<any | null> {
    try {
      const response = await service.checkUsername(category, stateValue)
      setSubmitting(false)
      setValidUsername && setValidUsername(true)
      setValidUsernameLocal(true)
      return response
    } catch (error) {
      const eReturn = error?.data?.username[0]

      if (eReturn?.remaining_time !== null) {
        setDelayTime(eReturn?.remaining_time)
      }

      setUsernameExistCount(Number(eReturn?.count))
      setApiError(error?.data?.username[0]?.message)
      setSubmitting(false)
      return error
    }
  }

  function usernameCallback() {
    if (stateValue === "") {
      return null
    }

    if (category === "LOGIN") {
      return null
    }

    if (errors === undefined && stateValue === "") {
      return null
    }

    if (errors === "필수 정보입니다.") {
      return null
    }

    setSubmitting(true)
    setTimeout(checkUsername, delayCall(delay !== 0 ? delay : delayTime))
  }

  function handleFocus() {
    setFocus(true)
    setValidUsernameLocal(false)
    onChange("")
    onFocus()
    setApiError("")
  }

  function handleBlur() {
    usernameCallback()
    return [setFocus(false), onBlur && onBlur()]
  }

  const borderClass = `
    ${s.inputBordered}
    ${FocusErrorRequired ? s.inputBorderedActive : ``}
    ${NotFocusErrorRequired || HasApiError ? s.inputBorderedError : ``}
    ${isFocused ? s.inputBorderedActive : ``}
    ${HasNoError ? s.inputBorderedValid : ``}
    ${isLogin && HasNoErrorLogin ? s.inputBorderedValid : ``}
    ${!isLogin && submitting ? s.inputBorderedValid : ``}
    ${isFindPassword && HasNoErrorLogin ? s.inputBorderedValid : ``}
    ${isFindPassword && submitting ? s.inputBorderedValid : ``}
    ${isRegister && NotFocusErrorRequired ? s.inputBorderedError : ``}
  `

  const labelClass = `
    ${s.labelInfo}
    ${FocusErrorRequired || HasApiError ? s.labelInfoError : ``}
    ${NotFocusErrorRequired ? s.labelInfoError : ``}
    ${isFocused ? s.labelInfoActive : ``}
    ${submitting ? s.labelInfoDefault : ``}
  `

  const labelClassLogin = `
    ${s.labelInfo}
    ${FocusErrorRequired ? s.labelInfoDefault : ``}
    ${NotFocusErrorRequired ? s.labelInfoError : ``}
  `

  const messageClass = `
    ${s.usernameMessage}
    ${FocusErrorRequired ? s.usernameMessageDefault : ``}
    ${NotFocusErrorRequired || HasApiError ? s.usernameMessageError : ``}
    ${isFocused ? s.usernameMessageDefault : ``} 
    ${HasNoError ? s.usernameMessageValid : ``} 
    ${isFindPassword && HasNoError ? s.usernameMessageDefault : ``}
  `

  return (
    <div className={s.wrapperUsername}>
      <div className={borderClass}>
        <div className={s.labelContainer}>
          <div className={s.label}>{label}</div>
          {labelHelper && (
            <div className={isLogin ? labelClassLogin : labelClass}>
              <span className="message">
                <span className={s.parenStyle}>{`( `}</span>
                {labelHelper}
                <span className={s.parenStyle}>{` )`}</span>
              </span>
            </div>
          )}
        </div>
        <input
          maxLength={14}
          key={label}
          disabled={disabled}
          className={isIOS ? s.formInputIOS : s.formInput}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete={isRegister ? "new-password" : "off"}
          name={label}
          type="text"
          ref={register}
          placeholder={placeholder}
          onKeyUp={e => tabElement(e, true)}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.toLowerCase()
            const reg = /^[a-zA-Z0-9]*$/
            if (reg.test(value)) {
              onChange(value)
            } else {
              onChange(value.replace(/[^a-z0-9]/gi, ""))
            }
          }}
        />
      </div>

      {!isLogin && submitting && (
        <div style={{position: "absolute", top: "3px", right: 0, zIndex: 1}}>
          <Loader />
        </div>
      )}

      <div>
        <div className={messageClass}>
          <Fade top opposite when={NotFocusErrorRequired}>
            <div className={s.usernameHelper}>
              <div>
                <IconExclamation color={cp.red} />
              </div>
              <div className={s.usernameHelperTextError}>{`필수 정보입니다.`}</div>
            </div>
          </Fade>

          <Fade top opposite when={!isFocused && HasApiError}>
            <div className={s.usernameHelper}>
              <div>
                <IconExclamation color={cp.red} />
              </div>
              <div
                className={s.usernameHelperTextError}
              >{`사용할수 없는 아이디 입니다.`}</div>
            </div>
          </Fade>

          <Fade top opposite when={!isFindPassword && HasNoError}>
            <div className={s.usernameHelper}>
              <div className={s.usernameHelperTextValid}>{`멋진 아이디네요!`}</div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default FormInput
