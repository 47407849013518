import React, {CSSProperties, Suspense, useContext, useEffect, useRef} from "react"
import styles from "./styles.module.css"
import SwiperCore, {Navigation, Scrollbar, A11y} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"

import {pathType, useNavigate} from "hooks/useNavigate"
import {NextIcon} from "./NextIcon"
import {PrevIcon} from "./PrevIcon"
import {ThemeContext} from "context/ThemeContext"
import LandingPage from "components/LandingPage"

// install Swiper modules
SwiperCore.use([Navigation, Scrollbar, A11y])

interface ImageData {
  src: string
  caption: string
  path: pathType
}

interface HomeCarouselProps {
  images: ImageData[]
}
interface CSSCustomProperties extends CSSProperties {
  "--carouselArrowsColor"?: string
}

const HomeCarousel: React.FC<HomeCarouselProps> = ({images}) => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--carouselArrowsColor": theme?.colors.carouselArrowsColor,
  }

  const swiperRef = useRef(null)
  useEffect(() => {
    if (swiperRef.current && images.length > 0) {
      swiperRef.current.update() // Re-initialize Swiper after images are loaded
    }
  }, [images])

  const {handleNavigate} = useNavigate()

  const handleNavigateTo = (path: pathType) => {
    handleNavigate(path)
  }

  return (
    <div className={styles.swiperContainer} style={custom_colors}>
      <Suspense fallback={<LandingPage />}>
        <Swiper
          observer={true}
          observeParents={true}
          slidesPerView={3}
          spaceBetween={15}
          allowTouchMove={true}
          className={`${styles.swiper} customSwiper`}
          navigation={{
            nextEl: ".arrow_right",
            prevEl: ".arrow_left",
            disabledClass: `${styles.swiper_button_disabled}`,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <div
                className={styles.carouselItem}
                onClick={() => handleNavigateTo(image.path)}
              >
                <img src={image.src} alt={image.caption} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={`${styles.arrow_left} ${styles.arrow} arrow_left`}>
          {/* <img src={prevArrow} alt="prev arrow" /> */}
          <PrevIcon />
        </div>
        <div className={`${styles.arrow_right} ${styles.arrow} arrow_right`}>
          <NextIcon />
        </div>
      </Suspense>
    </div>
  )
}

export default HomeCarousel
