import axios, {AxiosError, AxiosResponse, AxiosRequestConfig} from "axios"
import {useUserStore} from "stores/userStore"

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
})

const onError = function (error: AxiosError) {
  console.error("Request Failed:", error.config)

  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx

    console.log(error.response.status)

    if (error.response.status === 401) {
      const user = useUserStore.getState()
      user.logout()
      // window.location.reload()
    }

    console.error("Status:", error.response.status)
    console.error("Data:", error.response.data)
    console.error("Headers:", error.response.headers)
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error("Error Message:", error.message)
    const url = error.config?.url
    if (url === "login") {
      return Promise.reject(error)
    }
  }

  return Promise.reject(error.response || error.message)
}

export const strongTypedRequest = async <T,>(
  options: AxiosRequestConfig,
): Promise<T> => {
  const onSuccess = (response: AxiosResponse<T>) => {
    return response.data
  }

  return client(options).then(onSuccess).catch(onError)
}

const request = async (options: AxiosRequestConfig): Promise<AxiosResponse> => {
  const onSuccess = (response: AxiosResponse<any>) => {
    return response
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
