// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useContext, useState, useEffect} from "react"
import {useForm, Controller} from "react-hook-form"
import {useModal} from "react-modal-hook"
import {useHistory} from "react-router-dom"
import cx from "classnames"
/** Input */
import FormInput from "components/Forms/Input/Default"
import FormInputUsername from "components/Forms/Input/Username"
import FormInputPassword from "components/Forms/Input/Password/Register"
import FormInputPasswordConfirm from "components/Forms/Input/Password/Confirm"
import FormMobileNumberInput from "components/Forms/Input/Mobile/MobileUnregistered"
import FormInputReferrer from "components/Forms/Input/Referrer"
import Captcha from "components/Forms/Captcha"
import FormCheckbox from "components/Forms/Checkbox/default"
import Button from "screens/MyProfile/components/DateRangePicker/Button"
import {REGEX_HANGUL_ONLY} from "screens/MyProfile/config"
/**
 * Components
 */
import AlertModal from "components/ModalAlert"
/** Config */
import config from "../../Config"
/**
 * Context
 */
import {Context as RegisterContext} from "screens/Account/Register/Context/Context"
/**
 * Styles
 */
import s from "./registerform.module.scss"
import useFooterStore from "stores/Footer/useFooterStore"
import shallow from "zustand/shallow"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"

const regex = /[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/g

function Form({className}: {className?: string}): JSX.Element {
  const {
    isInvalidReferrer,
    registerDetails,
    referrerSubmitting,
    localTerms,
    localReferrer,
    registerDelays,
    setDetails,
    setInvalidReferrer,
    setLocalReferrer,
    setLocalTerms,
    handleSubmitForm,
    referrerCallback,
    setRegisterDetails,
  } = useContext(RegisterContext)
  const rd = registerDetails
  const {setSelected} = useFooterStore(store => {
    return {setSelected: store.setSelected}
  }, shallow)
  const {close} = useRegisterModalStore(
    store => ({
      close: store.close,
    }),
    shallow,
  )
  const [isReferrerFocus, setReferrerFocus] = useState<boolean>(false)
  const [isValidName, setValidName] = useState<boolean>(false)
  const [isValidOtp, setIsValidOtp] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>(null)
  const [showModal, hideModal] = useModal(
    () => (
      <AlertModal
        message={alertMessage}
        action={() => [hideModal(), setAlertMessage(null)]}
      />
    ),
    [alertMessage],
  )

  const {
    username,
    password,
    passwordConfirm,
    playerName,
    playerMobile,
    referrer,
    captcha,
    terms,
  } = config

  const {
    errors,
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
    setError,
  } = useForm({
    mode: "all",
  })

  const category = "REGISTER_PLAYER"

  function onSubmit() {
    handleSubmitForm()
  }

  function inputReferrerBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    const value = e.target.value
    referrerCallback(value)
  }

  function setInputValue(key: string, value: string, fieldKey: string) {
    setValue(fieldKey, value)
    setRegisterDetails({
      ...registerDetails,
      [key]: value,
    })
  }

  function inputFocus(key: string, errorKey: string): void {
    setDetails && setDetails(key, "")
    setValue(errorKey, "")
    clearErrors(errorKey)
  }

  function inputFocusMobile(): void {
    // Clear Errors
    setDetails && setDetails("mobile", "010-")
    setValue("mobile", "010-")
    clearErrors("mobile")
  }

  const validReferrer = localReferrer !== ""

  const isValid =
    rd?.username !== "" &&
    rd?.usernameValid &&
    rd?.password !== "" &&
    rd?.password_confirm !== "" &&
    rd?.password === rd?.password_confirm &&
    rd?.name !== "" &&
    isValidName &&
    rd?.mobile !== "" &&
    rd?.otp !== "" &&
    rd?.captcha !== "" &&
    localTerms &&
    validReferrer &&
    !isReferrerFocus &&
    !isInvalidReferrer &&
    errors[`비밀번호`] === undefined

  useEffect(() => {
    if (rd?.password === "") {
      clearErrors(passwordConfirm?.label)
      setValue(passwordConfirm?.label, "")
      setDetails && setDetails("password_confirm", "")
    }
  }, [rd?.password])

  useEffect(() => {
    if (alertMessage === null) {
      return
    }

    showModal()
  }, [alertMessage])

  const handleOnChangeName = (value: string) => {
    const stringVal = value.match(/^[가-힣\s]+$/)
    const isNotHangul = REGEX_HANGUL_ONLY.test(value)
    const formatted = value.replaceAll(REGEX_HANGUL_ONLY, "")
    const hangulName = stringVal?.join("")

    setInputValue("name", formatted, playerName.label)
    if (isNotHangul) {
      setError(playerName.label, {
        type: "invalidFormat",
        message: "영문 이름은 가입이 불가능 합니다.",
      })
    } else if (hangulName) {
      setRegisterDetails({
        ...registerDetails,
        name: hangulName,
      })
      if (hangulName.length >= 2) setValidName(true)
      else setValidName(false)
    }
  }

  const handleValidateName = (name: string) => {
    const isValidLength = name?.length >= 2
    const isHangul = /^[가-힣\s]+$/

    if (name?.length === 0) {
      setValidName(false)
      setError(playerName.label, {
        type: "required",
        message: "필수 정보입니다.",
      })
    } else if (!isHangul.test(name)) {
      setValidName(false)
      setError(playerName.label, {
        type: "invalidFormat",
        message: "정확한 정보 입력이 필요합니다.",
      })
    } else if (!isValidLength) {
      setValidName(false)

      setError(playerName.label, {
        type: "min",
        message: "이름은 반드시 2글자 이상 입력해야합니다.",
      })
    } else {
      setValidName(true)
    }
  }

  return (
    <div className={cx(className)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.formRegisterWrapper}>
          {/* USERNAME */}
          <div className={s.inputBox}>
            <FormInputUsername
              category={category}
              stateValue={rd?.username}
              label={username.label}
              labelHelper={username.labelHelper}
              placeholder={username.placeholder}
              errors={errors[username.label]?.message}
              register={register(username.rules)}
              onChange={(value: string): void => {
                setRegisterDetails({
                  ...registerDetails,
                  username: value,
                })
                setValue(username?.label, value)
              }}
              onFocus={(): void => {
                setRegisterDetails({
                  ...registerDetails,
                  username: "",
                  usernameValid: false,
                })
                setValue(username?.label, "")
                clearErrors(username.label)
              }}
              setValidUsername={(validValue: boolean) =>
                setDetails && setDetails("usernameValid", validValue)
              }
              delay={registerDelays?.username}
            />
          </div>
          {/* PASSWORD */}
          <div className={s.inputBox}>
            <div
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#FFFFFF",
                position: "absolute",
                top: "-15px",
                border: "1px solid #FFFFFF",
              }}
            />
            <FormInputPassword
              name="password"
              stateValue={rd?.password}
              label={password.label}
              labelHelper={password.labelHelper}
              placeholder={password.placeholder}
              errors={errors[password.label]}
              register={register(password.rules)}
              onFocus={(): void => inputFocus("password", password.label)}
              onChange={(value: string): void => {
                setRegisterDetails({
                  ...registerDetails,
                  password: value,
                })
                setValue(password?.label, value)
              }}
              onKeyup={() =>
                rd?.password_confirm !== "" ? trigger(passwordConfirm?.label) : null
              }
              resetValue={(): void => inputFocus("password", password.label)}
            />
          </div>
          {/* CONFIRM PASSWORD */}
          <div className={s.inputBox}>
            <FormInputPasswordConfirm
              name="confirmpassword"
              passwordValue={rd?.password}
              stateValue={rd?.password_confirm}
              label={passwordConfirm?.label}
              placeholder={passwordConfirm?.placeholder}
              errors={errors[passwordConfirm?.label]?.message}
              register={register({
                ...password.rules,
                validate: value => {
                  if (rd?.password === "") {
                    return "valid"
                  } else {
                    return value === rd?.password || password.notMatch
                  }
                },
              })}
              onChange={(value: string): void => {
                setRegisterDetails({
                  ...registerDetails,
                  password_confirm: value,
                })
                setValue(passwordConfirm?.label, value)
                // trigger(passwordConfirm?.label)
              }}
              resetValue={(): void =>
                inputFocus("password_confirm", passwordConfirm?.label)
              }
            />
          </div>
          {/* NAME */}

          <div className={s.inputBox}>
            <div
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#FFFFFF",
                position: "absolute",
                top: "-15px",
                border: "1px solid #FFFFFF",
              }}
            />
            <FormInput
              stateValue={rd?.name}
              label={playerName.label}
              type="text"
              labelHelper={playerName.labelHelper}
              placeholder={playerName.placeholder}
              errors={errors[playerName.label]}
              maxLength={6}
              register={register(playerName.rules)}
              onFocus={() => inputFocus("name", playerName.label)}
              onBlur={() => handleValidateName(rd?.name)}
              onChange={value => handleOnChangeName(value)}
            />
          </div>
          {/* MOBILE */}
          <div className={s.inputBox}>
            <div
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#FFFFFF",
                position: "absolute",
                top: "-15px",
                border: "1px solid #FFFFFF",
              }}
            />
            <div>
              <Controller
                register={register}
                control={control}
                name="mobile"
                rules={{
                  required: "required",
                  validate: (value: string) => {
                    if (value.length === 13) {
                      return "valid"
                    } else {
                      return "invalid"
                    }
                  },
                }}
                as={
                  <FormMobileNumberInput
                    name="mobile"
                    category={category}
                    value={rd?.mobile}
                    label={playerMobile.label}
                    labelHelper={playerMobile.labelHelper}
                    placeholder={playerMobile.placeholder}
                    errors={errors?.mobile?.message}
                    inputRef={register({
                      required: "",
                    })}
                    getOTP={(otpValue: string): void =>
                      setRegisterDetails({
                        ...registerDetails,
                        otp: otpValue,
                      })
                    }
                    onFocus={(): void => inputFocusMobile()}
                    onSetValue={(mobileVal: string) =>
                      setRegisterDetails({
                        ...registerDetails,
                        mobile: mobileVal,
                      })
                    }
                    onBlur={() => null}
                    setIsValidOtp={setIsValidOtp}
                    delay={registerDelays?.isMobileExists}
                  />
                }
              />
            </div>
          </div>
          {/* Referrer */}
          <div className={s.inputBox}>
            <div
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#FFFFFF",
                position: "absolute",
                top: "-15px",
                border: "1px solid #FFFFFF",
              }}
            />
            <FormInputReferrer
              name="referrer"
              value={localReferrer}
              isValidReferrer={!isInvalidReferrer}
              label={referrer.label}
              labelHelper={referrer.labelHelper}
              placeholder={referrer.placeholder}
              errors={isInvalidReferrer}
              isSubmitting={referrerSubmitting}
              onChange={(value: string): void => {
                setLocalReferrer && setLocalReferrer(value)
              }}
              onFocus={() => {
                setInvalidReferrer && setInvalidReferrer(false)
                setReferrerFocus(true)
              }}
              onBlur={e => {
                inputReferrerBlur(e)
                setReferrerFocus(false)
              }}
              register={register}
            />
          </div>
          {/* CAPTCHA */}
          <div className={s.inputBox}>
            <div
              style={{
                width: "100%",
                height: "30px",
                backgroundColor: "#FFFFFF",
                position: "absolute",
                top: "-10px",
                border: "1px solid #FFFFFF",
              }}
            />
            <Captcha
              value={rd?.captcha}
              label={captcha.label}
              type="register"
              onChange={(value: string) =>
                setRegisterDetails({
                  ...registerDetails,
                  captcha: value,
                })
              }
            />
          </div>
          {/* TERMS */}
          <div className={s.registerCheckbox}>
            <FormCheckbox
              keyName="register-terms"
              checked={localTerms}
              label={terms.label}
              onChange={() => setLocalTerms(!localTerms)}
            >
              <span className={s.registerTerms}>
                <span
                  className={s.registerTermsLink}
                  onClick={e => {
                    e.preventDefault()
                    window.open("/terms", "_blank")
                  }}
                >{`이용약관 `}</span>
                {` 및 `}
                <span
                  className={s.registerTermsLink}
                  onClick={e => {
                    e.preventDefault()
                    window.open("/privacy-policy", "_blank")
                  }}
                >
                  {`개인정보 보안`}
                </span>
                {`, `}
                <span className={s.highlight}>{`미성년자 가입 불가`}</span>
                {` 정책 동의`}
              </span>
            </FormCheckbox>
          </div>
          <div>
            <Button
              className={s.button}
              label={"가입하기"}
              type={isValid && !referrerSubmitting ? "read" : "disabled"}
              handleClick={() => handleSubmitForm()}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

const RegisterForm = React.memo(Form)
export default RegisterForm
