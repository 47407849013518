import React, {useState} from "react"
import shallow from "zustand/shallow"
import cx from "classnames"
import banner1 from "assets/login-banner-1.png"
import banner2 from "assets/login-banner-2.png"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import {LOGIN, FIND_ID, FORGOT_PASSWORD} from "components/LoginButton/contants"
import s from "components/LoginButton/Banner/banner.module.scss"

const Banner = () => {
  const [banner1Loaded, setIsBanner1Loaded] = useState(false)
  const [banner2Loaded, setIsBanner2Loaded] = useState(false)
  const {title} = useLoginModalStore(
    store => ({
      title: store.selectedTab,
    }),
    shallow,
  )

  return (
    <div className={s.container}>
      <div className={s.banner1}>
        <img
          onLoad={() => setIsBanner1Loaded(true)}
          src={banner1}
          className={cx({
            [s.login]: banner1Loaded && LOGIN === title,
            [s.findId]: banner1Loaded && FIND_ID === title,
            [s.findPassword]: banner1Loaded && FORGOT_PASSWORD === title,
          })}
        />
      </div>
      <div className={s.banner2}>
        <img
          onLoad={() => setIsBanner2Loaded(true)}
          src={banner2}
          className={cx({
            [s.login]: banner2Loaded && LOGIN === title,
            [s.findId]: banner2Loaded && FIND_ID === title,
            [s.findPassword]: banner2Loaded && FORGOT_PASSWORD === title,
          })}
        />
      </div>
    </div>
  )
}

export default Banner
