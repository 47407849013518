import request from "services/request"
import API from "services/Player/endpoints"

// import {getCookie} from "utils/hooks/useCookieStorage"
import {UserPromos, UserToken, ActiveFreeGames} from "types/App/user"
import {AxiosResponse} from "axios"
import {useUserStore} from "stores/userStore"

type ReferralRequest = {
  distributor_name?: string
}

const headers = () => {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
  }
}

const authHeaders = () => {
  const {authToken} = useUserStore.getState()

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  }
}

function getDetails() {
  return request({
    url: API.DETAILS,
    method: "GET",
    headers: authHeaders(),
  })
  // http.get<UserData>(API.DETAILS)
}

function refreshToken(): Promise<AxiosResponse<UserToken>> {
  return request({
    url: API.REFRESH_TOKEN,
    method: "POST",
    headers: authHeaders(),
  })
}

function sendActivityLog(): Promise<unknown> {
  return request({
    url: API.ACTIVITY_LOG,
    method: "POST",
    headers: authHeaders(),
  })
}

const calibrateLevelUpPoints = (): Promise<AxiosResponse<any>> => {
  return request({
    url: API.CALIBRATE_PTS,
    method: "GET",
    headers: authHeaders(),
  })
}

const getClaimedPromotions = (): Promise<AxiosResponse<{data: UserPromos[]}>> => {
  return request({
    url: API.CLAIMED_PROMO,
    method: "GET",
    headers: authHeaders(),
  })
}

const canRegister = (): Promise<AxiosResponse<{data: UserPromos[]}>> => {
  return request({
    url: API.CAN_REGISTER,
    method: "GET",
    headers: headers(),
  })
}

const canPlayFreeGames = (): Promise<AxiosResponse<{data: ActiveFreeGames}>> => {
  return request({
    url: API.CAN_PLAY_FREE_GAMES,
    method: "GET",
    headers: authHeaders(),
  })
}

const getReferralCode = (
  req: ReferralRequest,
): Promise<AxiosResponse<{code: string}>> => {
  return request({
    url: API.GET_REFERRAL,
    method: "GET",
    headers: headers(),
    params: req,
  })
}

export default {
  getDetails,
  refreshToken,
  sendActivityLog,
  calibrateLevelUpPoints,
  getClaimedPromotions,
  canRegister,
  canPlayFreeGames,
  getReferralCode,
}
